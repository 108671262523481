// Streaming Logos
import Netflix from '../img/netflix.svg';
import Hulu from '../img/hulu.svg';
import Peacock from '../img/peacock.svg';
import AppleTV from '../img/appleTV.svg';
// Tales of Arcadia: Wizards
import TOA_Logo from '../img/series/TOA_Wizards/logo.png';
import TOA_Poster from '../img/series/TOA_Wizards/poster.jpg';
import TOA_Hero from '../img/series/TOA_Wizards/inner/hero.jpg';
import TOA_Item1 from '../img/series/TOA_Wizards/inner/items/1.jpg';
import TOA_Item2 from '../img/series/TOA_Wizards/inner/items/2.jpg';
import TOA_Item3 from '../img/series/TOA_Wizards/inner/items/3.jpg';
import TOA_Consideration from '../img/series/TOA_Wizards/inner/consideration.jpg';
import TOA_Consideration_1 from "../img/series/TOA_Wizards/inner/gallery/consideration/Color-Key-1_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_2 from "../img/series/TOA_Wizards/inner/gallery/consideration/Color-Key-2_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_3 from "../img/series/TOA_Wizards/inner/gallery/consideration/Color-Key-3_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_4 from "../img/series/TOA_Wizards/inner/gallery/consideration/Color-Key-4_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_5 from "../img/series/TOA_Wizards/inner/gallery/consideration/Color-Key-5_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_6 from "../img/series/TOA_Wizards/inner/gallery/consideration/Color-Key-6_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_7 from "../img/series/TOA_Wizards/inner/gallery/consideration/Color-Key-7_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_8 from "../img/series/TOA_Wizards/inner/gallery/consideration/Douxie-and-Merlin-Viz-Dev_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_9 from "../img/series/TOA_Wizards/inner/gallery/consideration/Main-Title-Color-Key-1_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_10 from "../img/series/TOA_Wizards/inner/gallery/consideration/Main-Title-Color-Key-2_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_11 from "../img/series/TOA_Wizards/inner/gallery/consideration/Main-Title-Color-Key-3_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_12 from "../img/series/TOA_Wizards/inner/gallery/consideration/Main-Title-Color-Key-4_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_13 from "../img/series/TOA_Wizards/inner/gallery/consideration/Main-Title-Color-Key-5_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_14 from "../img/series/TOA_Wizards/inner/gallery/consideration/Main-Title-Color-Key-6_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_15 from "../img/series/TOA_Wizards/inner/gallery/consideration/Main-Title-Color-Key-7_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_16 from "../img/series/TOA_Wizards/inner/gallery/consideration/Main-Title-Color-Key-8_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_17 from "../img/series/TOA_Wizards/inner/gallery/consideration/Main-Title-Color-Key-9_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_18 from "../img/series/TOA_Wizards/inner/gallery/consideration/Morgana-and-Troll-Jim-Viz-Dev_Art-by-Alfonso-Blaas.jpg";
import TOA_Consideration_19 from "../img/series/TOA_Wizards/inner/gallery/consideration/Portrait-Concept-Art_Art-by-Alfonso-Blaas.jpg";
import TOA_Gallery from '../img/series/TOA_Wizards/inner/gallery/poster.jpg';
import TOA_Gallery_1 from "../img/series/TOA_Wizards/inner/gallery/stills/1_MorganaAndClaire.jpg";
import TOA_Gallery_2 from "../img/series/TOA_Wizards/inner/gallery/stills/2_ClaireDouxieSteve.jpg";
import TOA_Gallery_3 from "../img/series/TOA_Wizards/inner/gallery/stills/3_MerlinArthurClaireSteveDouxie.jpg";
import TOA_Gallery_4 from "../img/series/TOA_Wizards/inner/gallery/stills/4_TrollsPrepareForKillahead.jpg";
import TOA_Gallery_5 from "../img/series/TOA_Wizards/inner/gallery/stills/5_MerlinArchieDouxie.jpg";
import TOA_Gallery_6 from "../img/series/TOA_Wizards/inner/gallery/stills/7_ClaireJimDouxieSteve.jpg";
// KIPO AND THE AGE OF WONDERBEASTS
import KIPO_Logo from '../img/series/Kipo_AW/logo.png';
import KIPO_Poster from '../img/series/Kipo_AW/poster.jpg';
import KIPO_Hero from '../img/series/Kipo_AW/inner/hero.jpg';
import KIPO_Item1 from '../img/series/Kipo_AW/inner/items/1.jpg';
import KIPO_Item2 from '../img/series/Kipo_AW/inner/items/2.jpg';
import KIPO_Item3 from '../img/series/Kipo_AW/inner/items/3.jpg';
import KIPO_Bonus from '../img/series/Kipo_AW/inner/bonus.jpg';
import KIPO_Consideration from '../img/series/Kipo_AW/inner/consideration.jpg';
import KIPO_Consideration_1 from "../img/series/Kipo_AW/inner/gallery/consideration/Surface-Building_Art-by-Angela-Sung.jpg";
import KIPO_Consideration_2 from "../img/series/Kipo_AW/inner/gallery/consideration/Surface-Clover_Art-by-Angela-Sung.jpg";
import KIPO_Consideration_3 from "../img/series/Kipo_AW/inner/gallery/consideration/Surface-School_Art-by-Angela-Sung.jpg";
import KIPO_Consideration_4 from "../img/series/Kipo_AW/inner/gallery/consideration/Surface-Stairs_Art-by-Angela-Sung.jpg";
import KIPO_Consideration_5 from "../img/series/Kipo_AW/inner/gallery/consideration/Surface-Waterfall_Art-by-Angela-Sung.jpg";
import KIPO_Gallery from '../img/series/Kipo_AW/inner/gallery/poster.jpg';
// import KIPO_Gallery_1 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_01.jpg';
// import KIPO_Gallery_2 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_02.jpg';
// import KIPO_Gallery_3 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_03.jpg';
// import KIPO_Gallery_4 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_04.jpg';
// import KIPO_Gallery_5 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_05.jpg';
// import KIPO_Gallery_6 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_06.jpg';
// import KIPO_Gallery_7 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_07.jpg';
// import KIPO_Gallery_8 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_08.jpg';
// import KIPO_Gallery_9 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_09.jpg';
// import KIPO_Gallery_10 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_10.jpg';
// import KIPO_Gallery_11 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_11.jpg';
// import KIPO_Gallery_12 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_12.jpg';
// import KIPO_Gallery_13 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_13.jpg';
// import KIPO_Gallery_14 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_14.jpg';
// import KIPO_Gallery_15 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_15.jpg';
// import KIPO_Gallery_16 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_16.jpg';
// import KIPO_Gallery_17 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_17.jpg';
// import KIPO_Gallery_18 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_18.jpg';
// import KIPO_Gallery_19 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_19.jpg';
// import KIPO_Gallery_20 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_20.jpg';
// import KIPO_Gallery_21 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_21.jpg';
// import KIPO_Gallery_22 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_22.jpg';
// import KIPO_Gallery_23 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_23.jpg';
// import KIPO_Gallery_24 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_24.jpg';
// import KIPO_Gallery_25 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_25.jpg';
// import KIPO_Gallery_26 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_26.jpg';
// import KIPO_Gallery_27 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_27.jpg';
// import KIPO_Gallery_28 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_28.jpg';
// import KIPO_Gallery_29 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_29.jpg';
// import KIPO_Gallery_30 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_30.jpg';
// import KIPO_Gallery_31 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_31.jpg';
// import KIPO_Gallery_32 from '../img/series/Kipo_AW/inner/gallery/comic/Kipo webcomic_Page_32.jpg';
// JURASSIC WORLD: CAMP CRETACEOUS
import JW_Logo from '../img/series/JurassicWorld/logo.png';
import JW_Poster from '../img/series/JurassicWorld/poster.jpg';
import JW_Hero from '../img/series/JurassicWorld/inner/hero.jpg';
import JW_Item1 from '../img/series/JurassicWorld/inner/items/1.jpg';
import JW_Item2 from '../img/series/JurassicWorld/inner/items/2.jpg';
import JW_Item3 from '../img/series/JurassicWorld/inner/items/3.jpg';
import JW_Bonus from '../img/series/JurassicWorld/inner/bonus.jpg';
import JW_Consideration from '../img/series/JurassicWorld/inner/gallery/Jurassic-World-Camp-Cretaceous_Camp_Art-by-John-Paul-Balmet.jpg';
import JW_Consideration_1 from '../img/series/JurassicWorld/inner/gallery/Jurassic-World-Camp-Cretaceous_Camp_Color-Key_Art-by-Christophe-Vacher.jpg';
import JW_Consideration_2 from '../img/series/JurassicWorld/inner/gallery/Jurassic-World-Camp-Cretaceous_Camp-Morning_Color-Key_Art-by-Christophe-Vacher.jpg';
import JW_Consideration_3 from '../img/series/JurassicWorld/inner/gallery/Jurassic-World-Camp-Cretaceous_Color-Script_Art-by-Christophe-Vacher.jpg';
import JW_Consideration_4 from '../img/series/JurassicWorld/inner/gallery/Jurassic-World-Camp-Cretaceous_Color-Script-Panels_Art-by-Christophe-Vacher.jpg';
import JW_Consideration_5 from '../img/series/JurassicWorld/inner/gallery/Jurassic-World-Camp-Cretaceous_Isla-Nublar_Color-Key_Art-by-Christophe-Vacher.jpg';
import JW_Consideration_6 from '../img/series/JurassicWorld/inner/gallery/Jurassic-World-Camp-Cretaceous_Jungle_Color-Key_Art-by-Christophe-Vacher.jpg';
import JW_Consideration_7 from '../img/series/JurassicWorld/inner/gallery/Jurassic-World-Camp-Cretaceous_Jungle-Sunset_Color-Key_Art-by-Christophe-Vacher.jpg';
import JW_Consideration_8 from '../img/series/JurassicWorld/inner/gallery/Jurassic-World-Camp-Cretaceous_Outside-Jungle_Art-by-Christophe-Vacher.jpg';
import JW_Consideration_9 from '../img/series/JurassicWorld/inner/gallery/Jurassic-World-Camp-Cretaceous_Raptor_Color-Key_Art-by-Christophe-Vacher.jpg';
import JW_Consideration_10 from '../img/series/JurassicWorld/inner/gallery/Jurassic-World-Camp-Cretaceous_Treetops_Art-by-John-Paul-Balmet.jpg';
// THE MIGHTY ONES
import TMO_Logo from '../img/series/MightyOnes/logo.png';
import TMO_Poster from '../img/series/MightyOnes/poster.jpg';
import TMO_Hero from '../img/series/MightyOnes/inner/hero.jpg';
import TMO_Item1 from '../img/series/MightyOnes/inner/items/1.jpg';
import TMO_Item2 from '../img/series/MightyOnes/inner/items/2.jpg';
import TMO_Item3 from '../img/series/MightyOnes/inner/items/3.jpg';
import TMO_Bonus from '../img/series/MightyOnes/inner/bonus.jpg';
import TMO_Gallery from '../img/series/MightyOnes/inner/gallery/poster.jpg';
import TMO_Gallery_1 from '../img/series/MightyOnes/inner/gallery/YAR101_BackPorch_EXT_CLR_v002_JG.jpg';
import TMO_Gallery_2 from '../img/series/MightyOnes/inner/gallery/YAR101_BigCan_EXT_CLR_v002_JG.jpg';
import TMO_Gallery_3 from '../img/series/MightyOnes/inner/gallery/YAR101_BigRock_EXT_CLR_v003_BL.jpg';
import TMO_Gallery_4 from '../img/series/MightyOnes/inner/gallery/YAR101_BrokenGlasses_EXT_CLR_v001_BL.jpg';
import TMO_Gallery_5 from '../img/series/MightyOnes/inner/gallery/YAR101_Cave_Skylight_EXT_CLR_v006_BL.jpg';
import TMO_Gallery_6 from '../img/series/MightyOnes/inner/gallery/YAR101_Colorscript_ForAnnies.jpg';
import TMO_Gallery_7 from '../img/series/MightyOnes/inner/gallery/YAR101_Flashback_EXT_CLR_v002_BL.jpg';
import TMO_Gallery_8 from '../img/series/MightyOnes/inner/gallery/YAR101_PlayArea_Pan_EXT_CLR_v002_BL.jpg';
import TMO_Gallery_9 from '../img/series/MightyOnes/inner/gallery/YAR101_Pond_Towel_Area_Pan_EXT_CLR_v003_BL.jpg';
// import TMO_Gallery_10 from '../img/series/MightyOnes/inner/gallery/YAR101_YardToPond_Pan_EXT_CLR_v007_BL.jpg';
import TMO_Gallery_11 from '../img/series/MightyOnes/inner/gallery/YAR109_Ant_RUF_v003_BA.jpg';
import TMO_Gallery_12 from '../img/series/MightyOnes/inner/gallery/YAR109_BatsSamosaCarryingToilet_RUF_v004_BA.jpg';
import TMO_Gallery_13 from '../img/series/MightyOnes/inner/gallery/YAR109_BeetleBack_RUF_v003_BA.jpg';
import TMO_Gallery_14 from '../img/series/MightyOnes/inner/gallery/YAR109_BeeWalnutTadpoleSunflower_RUF_v003_BA.jpg';
import TMO_Gallery_15 from '../img/series/MightyOnes/inner/gallery/YAR109_BenTheStinkbug_StuckInGum_RUF_v004_BA.jpg';
import TMO_Gallery_16 from '../img/series/MightyOnes/inner/gallery/YAR109_BerryBigSmile_RUF_v003_BA.jpg';
import TMO_Gallery_17 from '../img/series/MightyOnes/inner/gallery/YAR109_BerryFaceSmushedOnRocksy_RUF_v003_BA.jpg';
import TMO_Gallery_18 from '../img/series/MightyOnes/inner/gallery/YAR109_BerryRocksy_FlameColor_RUF_v004_BA.jpg';
import TMO_Gallery_19 from '../img/series/MightyOnes/inner/gallery/YAR109_BerryRocksyFrowning_RUF_v003_BA.jpg';
import TMO_Gallery_20 from '../img/series/MightyOnes/inner/gallery/YAR109_Buggzo_RUF_v003_BA.jpg';
import TMO_Gallery_21 from '../img/series/MightyOnes/inner/gallery/YAR109_Flower_Seed_RUF_v004_BA.jpg';
import TMO_Gallery_22 from '../img/series/MightyOnes/inner/gallery/YAR109_GumWadA_RUF_v003_BA.jpg';
import TMO_Gallery_23 from '../img/series/MightyOnes/inner/gallery/YAR109_Mushroom_RUF_v004_BA.jpg';
import TMO_Gallery_24 from '../img/series/MightyOnes/inner/gallery/YAR109_OldBug_RUF_v004_BA.jpg';
import TMO_Gallery_25 from '../img/series/MightyOnes/inner/gallery/YAR109_RocksyBerryFaceHug_RUF_v003_BA.jpg';
import TMO_Gallery_26 from '../img/series/MightyOnes/inner/gallery/YAR109_RocksyBerryHoldingHands_RUF_v003_BA.jpg';
import TMO_Gallery_27 from '../img/series/MightyOnes/inner/gallery/YAR109_RocksyBerryHoldingHandsAngle_RUF_v003_BA.jpg';
import TMO_Gallery_28 from '../img/series/MightyOnes/inner/gallery/YAR109_RocksyBerrySmileCU_RUF_v003_BA.jpg';
import TMO_Gallery_29 from '../img/series/MightyOnes/inner/gallery/YAR109_RocksyBerryTwigBacks_RUF_v004_BA.jpg';
import TMO_Gallery_30 from '../img/series/MightyOnes/inner/gallery/YAR109_RocksyBerryWadOpenMouth_RUF_v003_BA.jpg';
import TMO_Gallery_31 from '../img/series/MightyOnes/inner/gallery/YAR109_RocksyExcited_RUF_v003_BA.jpg';
import TMO_Gallery_32 from '../img/series/MightyOnes/inner/gallery/YAR109_TeenyBug_RUF_v005_BA.jpg';
import TMO_Gallery_33 from '../img/series/MightyOnes/inner/gallery/YAR109_Thingsy_RUF_v003_BA.jpg';
import TMO_Gallery_34 from '../img/series/MightyOnes/inner/gallery/YAR109_TwigAgainstSprouts_RUF_v004_BA.jpg';
import TMO_Gallery_35 from '../img/series/MightyOnes/inner/gallery/YAR109_TwigBerryRocksyInAwe_RUF_v004_BA.jpg';
import TMO_Gallery_36 from '../img/series/MightyOnes/inner/gallery/YAR109_TwigBlowingBubble_RUF_v003_BA.jpg';
import TMO_Gallery_37 from '../img/series/MightyOnes/inner/gallery/YAR109_TwigBlurryHands_RUF_v003_BA.jpg';
import TMO_Gallery_38 from '../img/series/MightyOnes/inner/gallery/YAR109_TwigHandsCU_RUF_v003_BA.jpg';
import TMO_Gallery_39 from '../img/series/MightyOnes/inner/gallery/YAR109_TwigNotAmused_RUF_v002_BA.jpg';
// SHE-RA AND THE PRINCESS OF POWER
import SHE_Logo from '../img/series/SheRa/logo.png';
import SHE_Poster from '../img/series/SheRa/poster.jpg';
import SHE_Hero from '../img/series/SheRa/inner/hero.jpg';
import SHE_Item1 from '../img/series/SheRa/inner/items/1.jpg';
import SHE_Item2 from '../img/series/SheRa/inner/items/2.jpg';
import SHE_Item3 from '../img/series/SheRa/inner/items/3.jpg';
import SHE_Gallery from '../img/series/SheRa/inner/gallery/poster.jpg';
import SHE_Gallery_1 from "../img/series/SheRa/inner/gallery/stills/PerfumaFrostaAdoraScorpiaBow.jpg";
import SHE_Gallery_2 from "../img/series/SheRa/inner/gallery/stills/SHE_PD215_01111702_RGB.jpg";
import SHE_Gallery_3 from "../img/series/SheRa/inner/gallery/stills/SHE_PD216_01011919_RGB.jpg";
import SHE_Gallery_4 from "../img/series/SheRa/inner/gallery/stills/SHE_PD220_01015612.jpg";
import SHE_Gallery_5 from "../img/series/SheRa/inner/gallery/stills/SHE_PD220_01134423_RGB.jpg";
import SHE_Gallery_6 from "../img/series/SheRa/inner/gallery/stills/SHE_PD224_01112512.jpg";
import SHE_Gallery_7 from "../img/series/SheRa/inner/gallery/stills/SHE_PD224_01122017.jpg";
import SHE_Gallery_8 from "../img/series/SheRa/inner/gallery/stills/SHE_PD224_01173905.jpg";
import SHE_Gallery_9 from "../img/series/SheRa/inner/gallery/stills/SHE_PD225_01164623_RGB.jpg";
import SHE_Gallery_10 from "../img/series/SheRa/inner/gallery/stills/SHE_PD226_01171614_RGB.jpg";
import SHE_Gallery_11 from "../img/series/SheRa/inner/gallery/stills/SHE_PD226_01215117_RGB.jpg";
// FAST & FURIOUS: SPY RACERS
import FF_Logo from '../img/series/FastFurious/logo.png';
import FF_Poster from '../img/series/FastFurious/poster.jpg';
import FF_Hero from '../img/series/FastFurious/inner/hero.jpg';
import FF_Item1 from '../img/series/FastFurious/inner/items/FAST-FURIOUS_PEEK_1.jpg';
import FF_Item2 from '../img/series/FastFurious/inner/items/FAST-FURIOUS_PEEK_2.jpg';
import FF_Item3 from '../img/series/FastFurious/inner/items/FAST-FURIOUS_PEEK_3.jpg';
import FF_Gallery from '../img/series/FastFurious/inner/gallery/poster.jpg';
import FF_Consideration from '../img/series/FastFurious/inner/consideration.jpg';
import FF_Gallery_1 from 	"../img/series/FastFurious/inner/gallery/stills/FAST-FURIOUS_AG_1.jpg";
import FF_Gallery_2 from 	"../img/series/FastFurious/inner/gallery/stills/FAST-FURIOUS_AG_2.jpg";
import FF_Gallery_3 from 	"../img/series/FastFurious/inner/gallery/stills/FAST-FURIOUS_AG_3.jpg";
import FF_Gallery_4 from 	"../img/series/FastFurious/inner/gallery/stills/FAST-FURIOUS_AG_4.jpg";
import FF_Gallery_5 from 	"../img/series/FastFurious/inner/gallery/stills/FAST-FURIOUS_AG_5.jpg";
import FF_Gallery_6 from 	"../img/series/FastFurious/inner/gallery/stills/FAST-FURIOUS_AG_6.jpg";
import FF_Gallery_7 from 	"../img/series/FastFurious/inner/gallery/stills/FAST-FURIOUS_AG_7.jpg";
import FF_Gallery_8 from 	"../img/series/FastFurious/inner/gallery/stills/FAST-FURIOUS_AG_8.jpg";
import FF_Gallery_9 from 	"../img/series/FastFurious/inner/gallery/stills/FAST-FURIOUS_AG_9.jpg";
import FF_Gallery_10 from 	"../img/series/FastFurious/inner/gallery/stills/FAST-FURIOUS_AG_10.jpg";
import FF_Gallery_11 from 	"../img/series/FastFurious/inner/gallery/stills/FAST-FURIOUS_AG_11.jpg";



import FF_Consideration_1 from "../img/series/FastFurious/inner/gallery/consideration/FAST-FURIOUS_PD_1.jpg"
import FF_Consideration_2 from "../img/series/FastFurious/inner/gallery/consideration/FAST-FURIOUS_PD_2.jpg"
import FF_Consideration_3 from "../img/series/FastFurious/inner/gallery/consideration/FAST-FURIOUS_PD_3.jpg"
import FF_Consideration_4 from "../img/series/FastFurious/inner/gallery/consideration/FAST-FURIOUS_PD_4.jpg"
import FF_Consideration_5 from "../img/series/FastFurious/inner/gallery/consideration/FAST-FURIOUS_PD_5.jpg"
import FF_Consideration_6 from "../img/series/FastFurious/inner/gallery/consideration/FAST-FURIOUS_PD_6.jpg"
import FF_Consideration_7 from "../img/series/FastFurious/inner/gallery/consideration/FAST-FURIOUS_PD_7.jpg"
import FF_Consideration_8 from "../img/series/FastFurious/inner/gallery/consideration/FAST-FURIOUS_PD_8.jpg"
import FF_Consideration_9 from "../img/series/FastFurious/inner/gallery/consideration/FAST-FURIOUS_PD_9.jpg"
import FF_Consideration_10 from "../img/series/FastFurious/inner/gallery/consideration/FAST-FURIOUS_PD_10.jpg"

// MADAGASCAR: A LITTLE WILD
import MAD_Logo from '../img/series/Madagascar/logo.png';
import MAD_Poster from '../img/series/Madagascar/poster.jpg';
import MAD_Hero from '../img/series/Madagascar/inner/hero.jpg';
import MAD_Item1 from '../img/series/Madagascar/inner/items/1.jpg';
import MAD_Item2 from '../img/series/Madagascar/inner/items/2.jpg';
import MAD_Item3 from '../img/series/Madagascar/inner/items/3.jpg';
// ARCHIBALD'S NEXT BIG THING
import NBT_Logo from '../img/series/Archibalds_NBT/logo.png';
import NBT_Poster from '../img/series/Archibalds_NBT/poster.jpg';
import NBT_Hero from '../img/series/Archibalds_NBT/inner/hero.jpg';
import NBT_Item1 from '../img/series/Archibalds_NBT/inner/items/1.jpg';
import NBT_Item2 from '../img/series/Archibalds_NBT/inner/items/2.jpg';
// THE BOSS BABY: BACK IN BUSINESS (SERIES)
import BB_Logo from '../img/series/BossBaby_BIB/logo.png';
import BB_Poster from '../img/series/BossBaby_BIB/poster.jpg';
import BB_Hero from '../img/series/BossBaby_BIB/inner/hero.jpg';
import BB_Item1 from '../img/series/BossBaby_BIB/inner/items/1.jpg';
import BB_Item2 from '../img/series/BossBaby_BIB/inner/items/2.jpg';
// THE EPIC TALES OF CAPTAIN UNDERPANTS
import CU_Logo from '../img/series/CaptainUnderpants/logo.png';
import CU_Poster from '../img/series/CaptainUnderpants/poster.jpg';
import CU_Hero from '../img/series/CaptainUnderpants/inner/hero.jpg';
import CU_Item1 from '../img/series/CaptainUnderpants/inner/items/1.jpg';
import CU_Item2 from '../img/series/CaptainUnderpants/inner/items/2.jpg';
import CU_Item3 from '../img/series/CaptainUnderpants/inner/items/3.jpg';
// DOUG UNPLUGS
import DU_Logo from '../img/series/DougUnplugs/logo.png';
import DU_Poster from '../img/series/DougUnplugs/poster.jpg';
import DU_Hero from '../img/series/DougUnplugs/inner/hero.jpg';
import DU_Item1 from '../img/series/DougUnplugs/inner/items/1.jpg';
import DU_Item2 from '../img/series/DougUnplugs/inner/items/2.jpg';
import DU_Item3 from '../img/series/DougUnplugs/inner/items/3.jpg';
// DRAGONS RESCUE RIDERS
import DRR_Logo from '../img/series/DragonsRescueRiders/logo.png';
import DRR_Poster from '../img/series/DragonsRescueRiders/poster.jpg';
import DRR_Hero from '../img/series/DragonsRescueRiders/inner/hero.jpg';
import DRR_Item1 from '../img/series/DragonsRescueRiders/inner/items/1.jpg';
import DRR_Item2 from '../img/series/DragonsRescueRiders/inner/items/2.jpg';
import DRR_Item3 from '../img/series/DragonsRescueRiders/inner/items/3.jpg';
// RHYME TIME TOWN (SERIES)
import RTT_Logo from '../img/series/RhymeTimeTown/logo.png';
import RTT_Poster from '../img/series/RhymeTimeTown/poster.jpg';
import RTT_Hero from '../img/series/RhymeTimeTown/inner/hero.jpg';
import RTT_Item1 from '../img/series/RhymeTimeTown/inner/items/1.jpg';
import RTT_Item2 from '../img/series/RhymeTimeTown/inner/items/2.jpg';
import RTT_Item3 from '../img/series/RhymeTimeTown/inner/items/3.jpg';
// SPIRIT RIDING FREE: RIDING ACADEMY
import SRF_Logo from '../img/series/SpiritRidingAcademy/logo.png';
import SRF_Poster from '../img/series/SpiritRidingAcademy/poster.jpg';
import SRF_Hero from '../img/series/SpiritRidingAcademy/inner/hero.jpg';
import SRF_Item1 from '../img/series/SpiritRidingAcademy/inner/items/1.jpg';
import SRF_Item2 from '../img/series/SpiritRidingAcademy/inner/items/2.jpg';
// TROLLS: TROLLSTOPIA
import TROLL_Logo from '../img/series/Trollstopia/logo.png';
import TROLL_Poster from '../img/series/Trollstopia/poster.jpg';
import TROLL_Hero from '../img/series/Trollstopia/inner/hero.jpg';
import TROLL_Item1 from '../img/series/Trollstopia/inner/items/1.jpg';
import TROLL_Item2 from '../img/series/Trollstopia/inner/items/2.jpg';
import TROLL_Item3 from '../img/series/Trollstopia/inner/items/3.jpg';

import TROLL_Gallery from 	'../img/series/Trollstopia/inner/gallery/poster.jpg';
import TROLL_Gallery_1 from "../img/series/Trollstopia/inner/gallery/stills/1.jpg";
import TROLL_Gallery_2 from "../img/series/Trollstopia/inner/gallery/stills/2.jpg";
import TROLL_Gallery_3 from "../img/series/Trollstopia/inner/gallery/stills/3.jpg";
import TROLL_Gallery_4 from "../img/series/Trollstopia/inner/gallery/stills/4.jpg";
import TROLL_Gallery_5 from "../img/series/Trollstopia/inner/gallery/stills/5.jpg";
// WHERE'S WALDO?
import WW_Logo from '../img/series/WheresWaldo/logo.png';
import WW_Poster from '../img/series/WheresWaldo/poster.jpg';
import WW_Hero from '../img/series/WheresWaldo/inner/hero.jpg';
import WW_Item1 from '../img/series/WheresWaldo/inner/items/1.jpg';
/////
///GABBY'S DOLLHOSE
import GD_Logo from '../img/series/GabbysDollhouse/logo.png';
import GD_Poster from '../img/series/GabbysDollhouse/poster.jpg';
import GD_Hero from '../img/series/GabbysDollhouse/inner/hero.jpg';
import GD_Item1 from '../img/series/GabbysDollhouse/inner/items/1.jpg';
import GD_Item2 from '../img/series/GabbysDollhouse/inner/items/2.jpg';
import GD_Item3 from '../img/series/GabbysDollhouse/inner/items/3.jpg';
import GD_Gallery from '../img/series/GabbysDollhouse/inner/gallery/poster.jpg';
import GD_Gallery_1 from "../img/series/GabbysDollhouse/inner/gallery/stills/GABBY-DOLLHOUSE_AG_1.jpg";
import GD_Gallery_2 from "../img/series/GabbysDollhouse/inner/gallery/stills/GABBY-DOLLHOUSE_AG_2.jpg";
import GD_Gallery_3 from "../img/series/GabbysDollhouse/inner/gallery/stills/GABBY-DOLLHOUSE_AG_3.jpg";
import GD_Gallery_4 from "../img/series/GabbysDollhouse/inner/gallery/stills/GABBY-DOLLHOUSE_AG_4.jpg";
import GD_Gallery_5 from "../img/series/GabbysDollhouse/inner/gallery/stills/GABBY-DOLLHOUSE_AG_5.jpg";


// TROLLHUNTERS RISE OF THE TITANS
import TH_Logo from 			'../img/series/TrollHunters/logo.png';
import TH_Poster from 			'../img/series/TrollHunters/poster.jpg';
import TH_Hero from 			'../img/series/TrollHunters/inner/hero.jpg';
import TH_Item1 from 			'../img/series/TrollHunters/inner/items/TROLLHUNTERS_PEEK_1.jpg';
import TH_Item2 from 			'../img/series/TrollHunters/inner/items/TROLLHUNTERS_PEEK_2.jpg';
import TH_Item3 from 			'../img/series/TrollHunters/inner/items/TROLLHUNTERS_PEEK_3.jpg';
import TH_Gallery from 			'../img/series/TrollHunters/inner/gallery/poster.jpg';
//import TH_Consideration from 	'../img/series/TrollHunters/inner/consideration/poster.jpg';
import TH_Gallery_1 from 		"../img/series/TrollHunters/inner/gallery/stills/TROLLHUNTERS_AG_1.jpg";
import TH_Gallery_2 from 		"../img/series/TrollHunters/inner/gallery/stills/TROLLHUNTERS_AG_2.jpg";
import TH_Gallery_3 from 		"../img/series/TrollHunters/inner/gallery/stills/TROLLHUNTERS_AG_3.jpg";
import TH_Gallery_4 from 		"../img/series/TrollHunters/inner/gallery/stills/TROLLHUNTERS_AG_4.jpg";
import TH_Gallery_5 from 		"../img/series/TrollHunters/inner/gallery/stills/TROLLHUNTERS_AG_5.jpg";
import TH_Gallery_6 from 		"../img/series/TrollHunters/inner/gallery/stills/TROLLHUNTERS_AG_6.jpg";
import TH_Gallery_7 from 		"../img/series/TrollHunters/inner/gallery/stills/TROLLHUNTERS_AG_7.jpg";
// import TH_Gallery_8 from 		"../img/series/TrollHunters/inner/gallery/stills/TROLLHUNTERS_AG_8.jpg";
// import TH_Gallery_9 from 		"../img/series/TrollHunters/inner/gallery/stills/TROLLHUNTERS_AG_9.jpg";
// import TH_Gallery_10 from 		"../img/series/TrollHunters/inner/gallery/stills/TROLLHUNTERS_AG_10.jpg";
// import TH_Gallery_11 from 		"../img/series/TrollHunters/inner/gallery/stills/TROLLHUNTERS_AG_11.jpg";
// import TH_Consideration_1 from 	"../img/series/TrollHunters/inner/consideration/stills/TROLLHUNTERS_AG_1.jpg"
// import TH_Consideration_2 from 	"../img/series/TrollHunters/inner/consideration/stills/TROLLHUNTERS_AG_2.jpg"
// import TH_Consideration_3 from 	"../img/series/TrollHunters/inner/consideration/stills/TROLLHUNTERS_AG_3.jpg"
// import TH_Consideration_4 from 	"../img/series/TrollHunters/inner/consideration/stills/TROLLHUNTERS_AG_4.jpg"
// import TH_Consideration_5 from 	"../img/series/TrollHunters/inner/consideration/stills/TROLLHUNTERS_AG_5.jpg"
// import TH_Consideration_6 from 	"../img/series/TrollHunters/inner/consideration/stills/TROLLHUNTERS_AG_6.jpg"
// import TH_Consideration_7 from 	"../img/series/TrollHunters/inner/consideration/stills/TROLLHUNTERS_AG_7.jpg"
// import TH_Consideration_8 from 	"../img/series/TrollHunters/inner/consideration/stills/TROLLHUNTERS_AG_8.jpg"

///THE CROODS

import CR_Logo from 			'../img/series/Croods/logo.png';
import CR_Poster from 			'../img/series/Croods/poster.jpg';
import CR_Hero from 			'../img/series/Croods/inner/hero.jpg';
import CR_Item1 from 			'../img/series/Croods/inner/items/CROODS_PEEK_1.jpg';
import CR_Item2 from 			'../img/series/Croods/inner/items/CROODS_PEEK_2.jpg';
import CR_Item3 from 			'../img/series/Croods/inner/items/CROODS_PEEK_3.jpg';
import CR_Gallery from 			'../img/series/Croods/inner/gallery/poster.jpg';
//import CR_Consideration from 	'../img/series/Croods/inner/consideration/poster.jpg';
import CR_Gallery_1 from 		"../img/series/Croods/inner/gallery/stills/CROODS_AG_1.jpg";
import CR_Gallery_2 from 		"../img/series/Croods/inner/gallery/stills/CROODS_AG_2.jpg";
import CR_Gallery_3 from 		"../img/series/Croods/inner/gallery/stills/CROODS_AG_3.jpg";
import CR_Gallery_4 from 		"../img/series/Croods/inner/gallery/stills/CROODS_AG_4.jpg";
import CR_Gallery_5 from 		"../img/series/Croods/inner/gallery/stills/CROODS_AG_5.jpg";
import CR_Gallery_6 from 		"../img/series/Croods/inner/gallery/stills/CROODS_AG_6.jpg";
import CR_Gallery_7 from 		"../img/series/Croods/inner/gallery/stills/CROODS_AG_7.jpg";
import CR_Gallery_8 from 		"../img/series/Croods/inner/gallery/stills/CROODS_AG_7.jpg";

export const projects = [
	// Tales of Arcadia: Wizards
	{
		"id": 0,
		"layout": "first",
		"name": "Tales of Arcadia: Wizards",
		"slug": "wizards-tales-of-arcadia",
		"logo": TOA_Logo,
		"poster": TOA_Poster,
		"inner": {
			"hero": TOA_Hero,
			"copyright": "Dreamworks Tales of Arcadia © 2020 Dreamworks Animation LLC. All Rights Reserved.",
			"description": "From Academy Award®-winning filmmaker Guillermo del Toro and the seven-time Emmy® Award-winning team behind Trollhunters comes Dreamworks Tales of Arcadia: Wizards, the final chapter in the Tales of Arcadia trilogy. Wizard-in-training Douxie and the heroes of Arcadia embark on a time-bending adventure to medieval Camelot, culminating in an apocalyptic battle for the control of magic that will determine the fate of the universe.",
			"credits": [
				{
					"label": "Creator and Executive Producer",
					"person": "Guillermo del Toro"
				},
				{
					"label": "Executive Producers",
					"person": "Marc Guggenheim, Chad Hammes"
				},
				{
					"label": "Co-Executive Producers",
					"person": "Aaron Waltke and Chad Quandt"
				}
			],
			"streaming": [
				{
					"platform": "Netflix",
					"logo": Netflix,
					"link": "https://www.netflix.com/watch/80218108"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/6adVDTR79n4?autoplay=1",
					"poster": TOA_Item1,
					"title": "Series Trailer",
					"description": "The third and final chapter of the Tales of Arcadia trilogy takes audiences on an adventure through time."
				},
				{
					"video": "https://www.youtube.com/embed/rpePQS8dW04?autoplay=1",
					"poster": TOA_Item2,
					"title": "Floating Castle Battle",
					"description": "The Wizards team took to the skies for an epic battle between floating castles that pushed the limits of VFX in TV animation."
				},
				{
					"video": "https://www.youtube.com/embed/MJwUC3shv0g?autoplay=1",
					"poster": TOA_Item3,
					"title": "Three Wizards Fight",
					"description": "When Douxie, Merlin and Morgana finally face off, it's a stunning combination of feature quality FX and incredible character animation that brings this magical fight sequence to life."
				}
			],
			"consideration": {
				"poster": TOA_Consideration,
				"title": "Production Design by Alfonso Blaas",
				"description": "Alfonso Blaas created a stunning production design.",
				"btnText": "View Now",
				"images": [
					TOA_Consideration_1,
					TOA_Consideration_2,
					TOA_Consideration_3,
					TOA_Consideration_4,
					TOA_Consideration_5,
					TOA_Consideration_6,
					TOA_Consideration_7,
					TOA_Consideration_8,
					TOA_Consideration_9,
					TOA_Consideration_10,
					TOA_Consideration_11,
					TOA_Consideration_12,
					TOA_Consideration_13,
					TOA_Consideration_14,
					TOA_Consideration_15,
					TOA_Consideration_16,
					TOA_Consideration_17,
					TOA_Consideration_18,
					TOA_Consideration_19
				]
			},
			"gallery": {
				"poster": TOA_Gallery,
				"title": "Art Gallery",
				"btnText": "View Now",
				"images": [
					TOA_Gallery_1,
					TOA_Gallery_2,
					TOA_Gallery_3,
					TOA_Gallery_4,
					TOA_Gallery_5,
					TOA_Gallery_6
				]
			}
		}
	},
	// KIPO AND THE AGE OF WONDERBEASTS
	{
		"id": 1,
		"layout": "second",
		"name": "KIPO AND THE AGE OF WONDERBEASTS",
		"slug": "kipo-age-of-wonderbeasts",
		"logo": KIPO_Logo,
		"poster": KIPO_Poster,
		"inner": {
			"hero": KIPO_Hero,
			"copyright": "Dreamworks Kipo and the Age of Wonderbeasts © 2019 Dreamworks Animation LLC. All Rights Reserved.",
			"description": "After spending her entire life living in an underground burrow, a young girl named Kipo is thrust into an adventure on the surface of a fantastical post-apocalyptic Earth. She joins a ragtag group of survivors as they embark on a journey through a vibrant wonderland where everything trying to kill them is downright adorable.",
			"credits": [
				{
					"label": "Created and Executive Produced by",
					"person": "Radford Sechrist"
				},
				{
					"label": "Developed for Television & Executive Produced by",
					"person": "Bill Wolkoff"
				}
			],
			"streaming": [
				{
					"platform": "Netflix",
					"logo": Netflix,
					"link": "https://www.netflix.com/watch/80221553"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/25UHUbpFTtY?autoplay=1",
					"poster": KIPO_Item1,
					"title": "Series Trailer",
					"description": "Take a ride through the post-apocalyptic wonderland of Kipo and the Age of Wonderbeasts."
				},
				{
					"video": "https://www.youtube.com/embed/XqLpxBOyQf0?autoplay=1",
					"poster": KIPO_Item2,
					"title": "LGBTQ Representation Matters",
					"description": "Kipo and the Age of Wonderbeasts featured the first time a character in a kids animated series said the words \"I'm gay.\""
				},
				{
					"video": "https://www.youtube.com/embed/ZCWqen4Q8nY?autoplay=1",
					"poster": KIPO_Item3,
					"title": "The Music of Kipo",
					"description": "Composer Daniel Rojas created stunning original music and score that gave the series a distinct sound."
				},
			],
			"bonus": {
				"link": "https://www.polygon.com/2020/1/15/21067505/kipo-and-the-age-of-wonderbeasts-coming-out-scene",
				"poster": KIPO_Bonus,
				"title": "READ: Kipo and the Age of Wonderbeasts treats coming out like just another part of life. ",
				"description": "A short, sweet character moment says what most cartoons just show."
			},
			"consideration": {
				"poster": KIPO_Consideration,
				"title": "Production Design by Angela Sung",
				"description": "Blue Pigs And Mutant Mandrills: Designing The Post-Apocalypse In ‘Kipo And The Age Of Wonderbeasts’",
				"btnText": "View Now",
				"images": [
					KIPO_Consideration_1,
					KIPO_Consideration_2,
					KIPO_Consideration_3,
					KIPO_Consideration_4,
					KIPO_Consideration_5
				]
			},
			"gallery": {
				"poster": KIPO_Gallery,
				"title": "Digital Comic",
				"btnText": "View Now",
				"link": "http://dreamworksfyc.cia-adv.com/KipoWebComic.pdf"
				// "images": [
				// 	KIPO_Gallery_1,
				// 	KIPO_Gallery_2,
				// 	KIPO_Gallery_3,
				// 	KIPO_Gallery_4,
				// 	KIPO_Gallery_5,
				// 	KIPO_Gallery_6,
				// 	KIPO_Gallery_7,
				// 	KIPO_Gallery_8,
				// 	KIPO_Gallery_9,
				// 	KIPO_Gallery_10,
				// 	KIPO_Gallery_11,
				// 	KIPO_Gallery_12,
				// 	KIPO_Gallery_13,
				// 	KIPO_Gallery_14,
				// 	KIPO_Gallery_15,
				// 	KIPO_Gallery_16,
				// 	KIPO_Gallery_17,
				// 	KIPO_Gallery_18,
				// 	KIPO_Gallery_19,
				// 	KIPO_Gallery_20,
				// 	KIPO_Gallery_21,
				// 	KIPO_Gallery_22,
				// 	KIPO_Gallery_23,
				// 	KIPO_Gallery_24,
				// 	KIPO_Gallery_25,
				// 	KIPO_Gallery_26,
				// 	KIPO_Gallery_27,
				// 	KIPO_Gallery_28,
				// 	KIPO_Gallery_29,
				// 	KIPO_Gallery_30,
				// 	KIPO_Gallery_31,
				// 	KIPO_Gallery_32
				// ]
			}
		}
	},
	// JURASSIC WORLD: CAMP CRETACEOUS
	{
		"id": 2,
		"layout": "third",
		"name": "JURASSIC WORLD: CAMP CRETACEOUS",
		"slug": "jurassic-world-camp-cretaceous",
		"logo": JW_Logo,
		"poster": JW_Poster,
		"inner": {
			"hero": JW_Hero,
			"copyright": "Jurassic World Franchise © 2020 Universal City Studios LLC and Amblin Entertainment INC. Series © 2020 Dreamworks Animation LLC. All Rights Reserved.",
			"description": "Set against the timeline of the blockbuster film Jurassic World, Jurassic World: Camp Cretaceous follows a group of six teenagers chosen for a once-in-a-lifetime experience at a new adventure camp on the opposite side of Isla Nublar.  When dinosaurs wreak havoc across the island, the campers are unable to reach the outside world and will quickly go from strangers to friends to family as they band together to survive.",
			"credits": [
				{
					"label": "Executive Producers",
					"person": "Steven Spielberg, Colin Trevorrow, Frank Marshall, Scott Kreamer, Aaron Hammersley"
				}
			],
			"streaming": [
				{
					"platform": "Netflix",
					"logo": Netflix,
					"link": "https://www.netflix.com/watch/81009646"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/bUOpbuNgL4w?autoplay=1",
					"poster": JW_Item1,
					"title": "Series Trailer",
					"description": "Jurassic World: Camp Cretaceous opens up the world of Isla Nublar like never before."
				},
				{
					"video": "https://www.youtube.com/embed/EwaCQn1QVM0?autoplay=1",
					"poster": JW_Item2,
					"title": "Behind the Scenes",
					"description": "The cast and crew of Jurassic World: Camp Cretaceous take you behind the scenes of making an animated series during a global pandemic."
				},
				{
					"video": "https://www.youtube.com/embed/JMyPDy6-rEE?autoplay=1",
					"poster": JW_Item3,
					"title": "It's a Cattle Drive!",
					"description": "Go behind the epic cattle drive sequence in Season One, from storyboard artist Ed Tadem."
				},
			],
			"bonus": {
				"video": "https://www.youtube.com/embed/MPRlKqLn7AM?autoplay=1",
				"poster": JW_Bonus,
				"title": "Visual Effects in Jurassic World",
				"description": "Custom jungle lighting, underground bioluminescent rivers and a giant mosasaurus lagoon are just a few of the environments that the incredible FX team created."
			},
			"consideration": {
				"poster": JW_Consideration,
				"title": "Production Design by Christophe Vacher",
				"description": "Stunning locales, rich colors and lush environments bring Isla Nublar to life in the first-ever Jurassic World animated series.",
				"btnText": "View Now",
				"images": [
					JW_Consideration_1,
					JW_Consideration_2,
					JW_Consideration_3,
					JW_Consideration_4,
					JW_Consideration_5,
					JW_Consideration_6,
					JW_Consideration_7,
					JW_Consideration_8,
					JW_Consideration_9,
					JW_Consideration_10
				]
			}
		}
	},
	// THE MIGHTY ONES
	{
		"id": 3,
		"layout": "third",
		"name": "THE MIGHTY ONES",
		"slug": "mighty-ones",
		"logo": TMO_Logo,
		"poster": TMO_Poster,
		"inner": {
			"hero": TMO_Hero,
			"copyright": "Dreamworks The Mighty Ones © 2020 Dreamworks Animation LLC. All Rights Reserved.",
			"description": "In every backyard a secret world exists filled with tiny creatures. The Mighty Ones follows the hilarious adventures of the smallest of them: a twig, a pebble, a leaf and a strawberry who call themselves “the Mighty Ones.” These best friends live in an unkempt backyard belonging to a trio of equally unkempt humans who they mistake for gods. Despite their diminutive stature, The Mighty Ones are determined to live large and have fun in their wild world.",
			"credits": [
				{
					"label": "Creators and Executive Producers",
					"person": "Lynn Naylor and Sunil Hall"
				}
			],
			"streaming": [
				{
					"platform": "Peacock",
					"logo": Peacock,
					"link": "https://www.peacocktv.com/watch-online/tv/the-mighty-ones/8501865675229905112/seasons/1/episodes/rocksys-in-a-hole-lotta-trouble-the-great-invisi-barrier-episode-1/f1af496b-695d-3739-8f4a-79f9b539a141"
				},
				{
					"platform": "Hulu",
					"logo": Hulu,
					"link": "https://www.hulu.com/series/the-mighty-ones-7b34470f-f28a-4740-8840-82a7355ce2ac"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/mzCXDD3WOuU?autoplay=1",
					"poster": TMO_Item1,
					"title": "Series Trailer",
					"description": "A peek inside the weird backyard world of The Mighty Ones."
				},
				{
					"video": "https://www.youtube.com/embed/4sqmuzlY9J8?autoplay=1",
					"poster": TMO_Item2,
					"title": "Beware the Snowbeast",
					"description": "Take a look inside the weird world of The Mighty Ones, with storyboard artist Eric Jones."
				},
				{
					"video": "https://www.youtube.com/embed/VPubLCgfLu8?autoplay=1",
					"poster": TMO_Item3,
					"title": "Rocksy Sinks",
					"description": "It’s all fun and games until Rocksy rolls into the water!"
				},
			],
			"bonus": {
				"video": "https://www.youtube.com/embed/eFM4V8LM9FQ?autoplay=1",
				"poster": TMO_Bonus,
				"title": "Conversation with Creators",
				"description": "Series creators and executive producers Lynn Naylor and Sunil Hall talk about bringing this passion project to fruition."
			},
			"gallery": {
				"poster": TMO_Gallery,
				"title": "Art Gallery",
				"btnText": "View Now",
				"images": [
					TMO_Gallery_1,
					TMO_Gallery_2,
					TMO_Gallery_3,
					TMO_Gallery_4,
					TMO_Gallery_5,
					TMO_Gallery_6,
					TMO_Gallery_7,
					TMO_Gallery_8,
					TMO_Gallery_9,
					// TMO_Gallery_10,
					TMO_Gallery_11,
					TMO_Gallery_12,
					TMO_Gallery_13,
					TMO_Gallery_14,
					TMO_Gallery_15,
					TMO_Gallery_16,
					TMO_Gallery_17,
					TMO_Gallery_18,
					TMO_Gallery_19,
					TMO_Gallery_20,
					TMO_Gallery_21,
					TMO_Gallery_22,
					TMO_Gallery_23,
					TMO_Gallery_24,
					TMO_Gallery_25,
					TMO_Gallery_26,
					TMO_Gallery_27,
					TMO_Gallery_28,
					TMO_Gallery_29,
					TMO_Gallery_30,
					TMO_Gallery_31,
					TMO_Gallery_32,
					TMO_Gallery_33,
					TMO_Gallery_34,
					TMO_Gallery_35,
					TMO_Gallery_36,
					TMO_Gallery_37,
					TMO_Gallery_38,
					TMO_Gallery_39
				]
			}
		}
	},
	// SHE-RA AND THE PRINCESS OF POWER
	{
		"id": 4,
		"layout": "fourth",
		"name": "SHE-RA AND THE PRINCESS OF POWER",
		"slug": "she-ra-princess-of-power",
		"logo": SHE_Logo,
		"poster": SHE_Poster,
		"inner": {
			"hero": SHE_Hero,
			"copyright": "SHE-RA and associated trademarks and character copyrights are owned by and used under license from Mattel, Inc. Under license to Classic Media.",
			"description": "A reimagining of the ’80s hit series, She-Ra and the Princesses of Power is the story of an orphan named Adora, who leaves behind her former life in the evil Horde when she discovers a magic sword that transforms her into She-Ra, a mythical warrior princess. When she finds a new surrogate family in the Rebellion she unites a group of magical princesses in the ultimate fight against evil.",
			"credits": [
				{
					"label": "Executive Producers",
					"person": "Noelle Stevenson and Chuckles Austen"
				}
			],
			"streaming": [
				{
					"platform": "Netflix",
					"logo": Netflix,
					"link": "https://www.netflix.com/watch/80179762"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/7EOGP0zDl1M?autoplay=1",
					"poster": SHE_Item1,
					"title": "She-Ra and the Princesses of Power Final Season Featurette",
					"description": "Last year, fans saw She-Ra come to an epic conclusion. The cast and crew reflect on their journey."
				},
				{
					"video": "https://www.youtube.com/embed/cz9RkvMnje4?autoplay=1",
					"poster": SHE_Item2,
					"title": "LGBTQ Representation Matters",
					"description": "In one of the final scenes of the series, fans learned that love truly wins, backed by composer Sunna Wehrmeijer's gorgeous score."
				},
				{
					"poster": SHE_Item3,
					"link": "https://www.latimes.com/entertainment-arts/tv/story/2020-05-18/netflix-she-ra-series-finale-queer-love-wins",
					"title": "From the LA Times",
					"description": "‘She-Ra’ rewrote the script for TV’s queer love stories. Here’s why it matters."
				},
			],
			"gallery": {
				"poster": SHE_Gallery,
				"title": "Art Gallery",
				"btnText": "View Now",
				"images": [
					SHE_Gallery_1,
					SHE_Gallery_2,
					SHE_Gallery_3,
					SHE_Gallery_4,
					SHE_Gallery_5,
					SHE_Gallery_6,
					SHE_Gallery_7,
					SHE_Gallery_8,
					SHE_Gallery_9,
					SHE_Gallery_10,
					SHE_Gallery_11
				]
			}
		}
	},
	// FAST & FURIOUS: SPY RACERS
	{
		"id": 5,
		"layout": "first",
		"name": "FAST & FURIOUS: SPY RACERS",
		"slug": "fast-furious-spy-racers",
		"logo": FF_Logo,
		"poster": FF_Poster,
		"inner": {
			"hero": FF_Hero,
			"copyright": "Fast & Furious and all related elements TM & © 2019 Universal City Studios LLC. TV Series © 2019 Dreamworks Animation LLC. All rights reserved.",
			"description": "Teenager Tony Toretto follows in the footsteps of his cousin Dom when he and his friends are recruited by a government agency to infiltrate an elite racing league serving as a front for a nefarious crime organization bent on world domination. Tim Hedrick (Voltron Legendary Defender) and Bret Haaland (All Hail King Julien) will serve as executive producers and showrunners. The series is also executive produced by Vin Diesel and Chris Morgan who also serve as producers on the live-action Fast & Furious franchise.",
			"credits": [
				{
					"label": "Executive Producers and Showrunners",
					"person": "Tim Hedrick, Bret Haaland"
				},
				{
					"label": "Executive Producers",
					"person": "Vin Diesel, Neal H. Moritz, Chris Morgan"
				}
			],
			"streaming": [
				{
					"platform": "Netflix",
					"logo": Netflix,
					"link": "https://www.netflix.com/watch/80225085"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/CFri0FO4nlg?autoplay=1",
					"poster": FF_Item1,
					"title": "Series Trailer",
					"description": "Get ready to make some waves as the crew finds themselves on the run through land, air, and sea in the South Pacific."
				},
				{
					"video": "https://www.youtube.com/embed/5BV4c7qmbNc?autoplay=1",
					"poster": FF_Item2,
					"title": "Ms. Nowhere and Palindrome ",
					"description": "With tensions running high, will Ms. Nowhere and Palindrome be able to get past their differences and work together as a team?"
				},
				{
					"video": "https://www.youtube.com/embed/90JMgMHflvk?autoplay=1",
					"poster": FF_Item3,
					"title": "TBD",
					"description": "When the Spy Racers head to Africa in Season 3, they find themselves in a death-defying race across the dangerous Sahara."
				},
			],
			// "consideration": {
			// 	"poster": FF_Consideration,
			// 	"video": "https://www.youtube.com/embed/dW7ui2pegSI?autoplay=1",
			// 	"title": "Rafaela's Escape",
			// 	"description": "Season 3 kicked off with this epic sequence directed by Micah Gunnell.",
			// 	"btnText": "View Now"
			// },

			"consideration": {
				"poster": FF_Consideration,
				"title": "",
				"description": "",
				"btnText": "View Now",
				"images": [
					FF_Consideration_1,
					FF_Consideration_2,
					FF_Consideration_3,
					FF_Consideration_4,
					FF_Consideration_5,
					FF_Consideration_6,
					FF_Consideration_7,
					FF_Consideration_8,
					FF_Consideration_9,
					FF_Consideration_10
				]
			},


			"gallery": {
				"poster": FF_Gallery,
				"title": "Art Gallery",
				"btnText": "View Now",
				"images":[
					FF_Gallery_1,
					FF_Gallery_2,
					FF_Gallery_3,
					FF_Gallery_4,
					FF_Gallery_5,
					FF_Gallery_6,
					FF_Gallery_7,
					FF_Gallery_8,
					FF_Gallery_9,
					FF_Gallery_10,
					FF_Gallery_11
				]
			}
		}
	},
	// MADAGASCAR: A LITTLE WILD
	{
		"id": 6,
		"layout": "first",
		"name": "MADAGASCAR: A LITTLE WILD",
		"slug": "madagascar-a-little-wild",
		"logo": MAD_Logo,
		"poster": MAD_Poster,
		"inner": {
			"hero": MAD_Hero,
			"copyright": "Dreamworks Madagascar © 2020 Dreamworks Animation LLC. All Rights Reserved.",
			"description": "Lovable foursome Alex the Lion, Marty the Zebra, Melman the Giraffe and Gloria the Hippo steal the show in Madagascar: A Little Wild, from executive producer Johanna Stein and co-excecutive producer Dana Starfield. Capturing the iconic personalities of each of the four dynamos, Madagascar: A Little Wild showcases the team as kids residing in their rescue habitat at the Central Park Zoo. They might be small, but like everybody who lands in New York City, these little guys have big dreams and Madagascar: A Little Wild will follow all of their adventures.",
			"credits": [
				{
					"label": "Executive Producer",
					"person": "Johanna Stein"
				}
			],
			"streaming": [
				{
					"platform": "Peacock",
					"logo": Peacock,
					"link": "https://www.peacocktv.com/watch-online/tv/madagascar-a-little-wild/6494384629026654112/seasons/1"
				},
				{
					"platform": "Hulu",
					"logo": Hulu,
					"link": "https://www.hulu.com/series/madagascar-a-little-wild-7a11e023-5762-4980-bfce-7f337e4c28ef"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/nBi9xnUfeAw?autoplay=1",
					"poster": MAD_Item1,
					"title": "Series Trailer",
					"description": "The lovable Zoo Crew is back as their younger selves, living out big dreams in the big city."
				},
				{
					"video": "https://www.youtube.com/embed/G3fy2metCRM?autoplay=1",
					"poster": MAD_Item2,
					"title": "Disability Representation Matters",
					"description": "Madagascar: A Little Wild features a diverse cast of characters, including Dave and Pickles, a chimp who is deaf and his sister who speaks and translates ASL to the other animals."
				},
				{
					"video": "https://www.youtube.com/embed/nKHdtp7Znbc?autoplay=1",
					"poster": MAD_Item3,
					"title": "Best Original Song - Shine",
					"description": "When Gloria’s faith in her dancing is tested, she learns that she doesn’t need someone else to decide that she’s a prima hippo-rina! Twirl along to \"Shine\", written by the incredible Alana D and Jordan Yaeger, who have filled the world of Madagascar with incredible original songs in every episode."
				},
			]
		}
	},
	// ARCHIBALD'S NEXT BIG THING
	// {
	// 	"id": 7,
	// 	"layout": "first",
	// 	"name": "ARCHIBALD'S NEXT BIG THING",
	// 	"slug": "archibalds-next-big-thing",
	// 	"logo": NBT_Logo,
	// 	"poster": NBT_Poster,
	// 	"inner": {
	// 		"hero": NBT_Hero,
	// 		"copyright": "Dreamworks Archibald's Next Big Thing © 2019 Dreamworks Animation LLC. All Rights Reserved.",
	// 		"description": "From the creative mind of Tony Hale, Archibald's Next Big Thing follows the adventures of Archibald Strutter, a chicken who 'yes-ands' his way through life. Though living in the moment often leads him astray, Archibald always finds his way back home to an egg-shaped house in the center of Crackridge he shares with his three siblings, Sage, Finly, and Loy, and his trusty sidekick, Bea. Archibald's Next Big Thing is a fresh comedy about the importance of being present and celebrating the journey, not just the destination.",
	// 		"credits": [
	// 			{
	// 				"label": "Created and Executive Produced by",
	// 				"person": "Tony Hale"
	// 			},
	// 			{
	// 				"label": "Executive Producer",
	// 				"person": "Eric Fogel"
	// 			}
	// 		],
	// 		"streaming": [
	// 			{
	// 				"platform": "Netflix",
	// 				"logo": Netflix,
	// 				"link": "https://www.netflix.com/watch/80221550"
	// 			}
	// 		],
	// 		"items": [
	// 			{
	// 				"video": "https://www.youtube.com/embed/hsRpduH8Pw4?autoplay=1",
	// 				"poster": NBT_Item1,
	// 				"title": "Series Trailer",
	// 				"description": "Emmy winner Tony Hale brought his celebrated children's book to life in this charming and vibrant series."
	// 			},
	// 			{
	// 				"video": "https://www.youtube.com/embed/Djxa8tDillM?autoplay=1",
	// 				"poster": NBT_Item2,
	// 				"title": "Archibald's New Singing Voice",
	// 				"description": "No day is an ordinary day in Crackridge, especially when Archibald discovers his new Broadway-worthy vocals."
	// 			}
	// 		]
	// 	}
	// },
	// THE BOSS BABY: BACK IN BUSINESS (SERIES)
	{
		"id": 7,
		"layout": "first",
		"name": "THE BOSS BABY: BACK IN BUSINESS (SERIES)",
		"slug": "boss-baby-back-in-business",
		"logo": BB_Logo,
		"poster": BB_Poster,
		"inner": {
			"hero": BB_Hero,
			"copyright": "Dreamworks The Boss Baby Back In Business © 2018 Dreamworks Animation LLC. All Rights Reserved.",
			"description": "With the help of his big brother and partner in crime, Tim, the Boss Baby continues to navigate the cutthroat corporate jungle gym of BabyCorps, while angling to achieve the near-impossible: a work-life balance.",
			"credits": [
				{
					"label": "Executive Producer",
					"person": "Brandon Sawyer"
				}
			],
			"streaming": [
				{
					"platform": "Netflix",
					"logo": Netflix,
					"link": "https://www.netflix.com/watch/81037060"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/aPu6yQ0OrG8?autoplay=1",
					"poster": BB_Item1,
					"title": "Series Trailer",
					"description": "The Boss Baby is back in season 4 of the incredibly popular series."
				},
				{
					"video": "https://www.youtube.com/embed/KX1av1R35PU?autoplay=1",
					"poster": BB_Item2,
					"title": "The Boss Baby: Get That Baby! Interactive Special",
					"description": "You can be the boss in this interactive Special Production that puts the viewer in the C-Suite."
				}
			]
		}
	},
	// THE EPIC TALES OF CAPTAIN UNDERPANTS 
	{
		"id": 8,
		"layout": "first",
		"name": "THE EPIC TALES OF CAPTAIN UNDERPANTS",
		"slug": "epic-tales-of-captain-underpants",
		"logo": CU_Logo,
		"poster": CU_Poster,
		"inner": {
			"hero": CU_Hero,
			"copyright": "Dreamworks Captain Underpants © 2020 Dreamworks Animation LLC. All Rights Reserved.",
			"description": "The team behind The Epic Tales of Captain Underpants had an equally epic year, breaking format and rolling out three distinct special productions for fans on Netflix: the Epic Choice-O-Rama interactive special, Captain Underpants in Space and Captain Underpants: Mega Blissmas. Whether George and Harold and friends were launched into space, putting their own spin on the Christmas holiday or letting the viewer make the choices that would hopefully save their treehouse, The Epic Tales of Captain Underpants never fails to capture the creativity and imagination of Dav Pilkey's best-selling books.",
			"credits": [
				{
					"label": "Executive Producer",
					"person": "Peter Hastings"
				}
			],
			"streaming": [
				{
					"platform": "Netflix",
					"logo": Netflix,
					"link": "https://www.netflix.com/watch/81147421"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/2UHylAUXrpA?autoplay=1",
					"poster": CU_Item1,
					"title": "Series Trailer",
					"description": "George and Harold are in for the ultimate field trip: outer space!"
				},
				{
					"video": "https://www.youtube.com/embed/6WMaYHCmV1k?autoplay=1",
					"poster": CU_Item2,
					"title": "Epic Choice-O-Rama Trailer",
					"description": "Imagine a world where you can control what adventures George and Harold go on. Oh wait, this is that world!"
				},
				{
					"video": "https://www.youtube.com/embed/AHAHqkOGIn0?autoplay=1",
					"poster": CU_Item3,
					"title": "Mega Blissmas Trailer",
					"description": "George and Harold travel back in time to convince Santa that a few ideas from their new version of Christmas (Blissmas) should be incorporated into his holiday. But will replacing red bows with laser shows work out as they had hoped?"
				}
			]
		}
	},
	// DOUG UNPLUGS
	{
		"id": 9,
		"layout": "first",
		"name": "DOUG UNPLUGS",
		"slug": "doug-unplugs",
		"logo": DU_Logo,
		"poster": DU_Poster,
		"inner": {
			"hero": DU_Hero,
			"copyright": "Dreamworks Doug Unplugs © 2020 Dreamworks Animation LLC. All Rights Reserved.",
			"description": "Based on the critically acclaimed children’s book by Dan Yaccarino, Dreamworks' Doug Unplugs follows a curious six-year-old robot, Doug, as he ‘plugs in’ to learn new facts about the world every day. Sensing there’s more to life than just the facts, Doug journeys into the human world in search of experience. And to do that, Doug has to...unplug. With the help of his best friend, a human ball of energy, Emma, the two discover all the simple wonders of the world. Join Doug as he learns about the intersection of technology and humanity, and how they both keep us truly connected!",
			"credits": [
				{
					"label": "Executive Producers",
					"person": "Aliki Theofilopoulos and Jim Nolan"
				}
			],
			"streaming": [
				{
					"platform": "Apple TV",
					"logo": AppleTV,
					"link": "https://tv.apple.com/us/show/doug-unplugs/umc.cmc.3oxevpgaoqomu820s0e70ri75"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/AHnHSr7_V2Q?autoplay=1",
					"poster": DU_Item1,
					"title": "Series Trailer",
					"description": "Meet Doug, the boy robot who’s taking on the world one curious question at a time."
				},
				{
					"video": "",
					"poster": DU_Item2,
					"title": "Conversation with Creators",
					"description": "Executive producers Aliki Theofilopoulos and Jim Nolan chat about their new series."
				},
				{
					"video": "https://www.youtube.com/embed/ZSARM5DtAc8?autoplay=1",
					"poster": DU_Item3,
					"title": "Doug Explores the Woods",
					"description": "Doug and his friends love to explore the great outdoors."
				}
			]
		}
	},
	// DRAGONS RESCUE RIDERS
	{
		"id": 10,
		"layout": "first",
		"name": "Dragons Rescue Riders",
		"slug": "dragons-rescue-riders",
		"logo": DRR_Logo,
		"poster": DRR_Poster,
		"inner": {
			"hero": DRR_Hero,
			"copyright": "Dreamworks Dragons © 2019 Dreamworks Animation LLC. All Rights Reserved.",
			"description": "In a faraway corner of the viking and dragon world, viking twins Dak and Leyla were rescued and raised by dragons, growing up learning to speak their language. In addition to a full season of episodes, the Dragons Rescue Riders team also debuted two incredible special productions. The first, Hunt for the Golden Dragon, sets the young Rescue Riders off on an adventure to save the precious Golden Dragon. And the Secret of the Songwing was a music-filled special that found the people of Huttsgalor transfixed by the Songwing dragon's harmonic hypnosis.",
			"credits": [
				{
					"label": "Executive Producer",
					"person": "Jack Thomas"
				},
				{
					"label": "Co-Executive Producer",
					"person": "Brian Roberts"
				}
			],
			"streaming": [
				{
					"platform": "Netflix",
					"logo": Netflix,
					"link": "https://www.netflix.com/watch/80218107"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/7h8HkvJxuDw?autoplay=1",
					"poster": DRR_Item1,
					"title": "Series Trailer",
					"description": "Dak, Leyla and dragon friends work together to save the day."
				},
				{
					"video": "https://www.youtube.com/embed/u-MOnjM2Lvg?autoplay=1",
					"poster": DRR_Item2,
					"title": "Hunt for the Golden Dragon Trailer",
					"description": "The Rescue Riders are tasked with their most adventurous and daring rescue mission yet: keep the Golden Dragon and her egg safe from the band of pirates searching for her nest!"
				},
				{
					"video": "https://www.youtube.com/embed/Pn4P1IYdQuU?autoplay=1",
					"poster": DRR_Item3,
					"title": "Secrets of the Songwing Trailer",
					"description": "The Rescue Riders race to save Aggro after she falls under the spell of Melodia, a Songwing dragon with a melodic call that enchants anyone who hears it!"
				}
			]
		}
	},
	// RHYME TIME TOWN (SERIES)
	{
		"id": 11,
		"layout": "first",
		"name": "Rhyme Time Town",
		"slug": "rhyme-time-town",
		"logo": RTT_Logo,
		"poster": RTT_Poster,
		"inner": {
			"hero": RTT_Hero,
			"copyright": "Dreamworks Rhyme Time Town © 2020 Dreamworks Animation LLC. All Rights Reserved.",
			"description": "All aboard to Dreamworks' Rhyme Time Town, where Daisy the Puppy and Cole the Kitten are ready to guide young children through a colorful and adventurous world filled with nursery rhymes and imaginative play.",
			"credits": [
				{
					"label": "Executive Producer",
					"person": "Jack Thomas"
				},
				{
					"label": "Co-Executive Producer",
					"person": "Brian Roberts"
				}
			],
			"streaming": [
				{
					"platform": "Netflix",
					"logo": Netflix,
					"link": "https://www.netflix.com/watch/80226787"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/TEe-Fn-XZY8?autoplay=1",
					"poster": RTT_Item1,
					"title": "Series Trailer",
					"description": "Join Daisy the Puppy and Cole the Kitten in a colorful world of nursery rhymes and imagination."
				},
				{
					"video": "https://www.youtube.com/embed/2h4Kd-ay0IE?autoplay=1",
					"poster": RTT_Item2,
					"title": "Sing-a-longs Trailer",
					"description": "All aboard to Rhyme Time Town, where Daisy the Puppy and Cole the Kitten go from one sing-a-long adventure to the next."
				},
				{
					"video": "https://www.youtube.com/embed/9vggFJsNDvM?autoplay=1",
					"poster": RTT_Item3,
					"title": "Bhangra Boogie Clip",
					"description": "Mayor Spoon teaches her nieces and nephews a dance routine to help them burn off their energy!"
				}
			]
		}
	},
	// SPIRIT RIDING FREE: RIDING ACADEMY
	{
		"id": 12,
		"layout": "first",
		"name": "SPIRIT RIDING FREE: RIDING ACADEMY",
		"slug": "spirit-riding-free-riding-academy",
		"logo": SRF_Logo,
		"poster": SRF_Poster,
		"inner": {
			"hero": SRF_Hero,
			"copyright": "Dreamworks Spirit © 2019 Dreamworks Animation LLC. All Rights Reserved.",
			"description": "Lucky, Pru and Abigail are preparing to leave Miradero on their next, exciting adventure as they move away to boarding school at Palamino Bluffs Riding Academy! But moving far from home definitely comes with challenges as the girls are faced with fresh responsibilities and experiences. From meeting their new competition, the BUDs, to fitting in among their fellow Foals (first-term students) their transition to the academy is anything but easy. Along the way the PALs will make new friends and explore their individual talents as they grow up and discover their new home together.",
			"credits": [
				{
					"label": "Executive Producer",
					"person": "Jim Schumann"
				},
				{
					"label": "Co-Executive Producer",
					"person": "May Chan"
				}
			],
			"streaming": [
				{
					"platform": "Netflix",
					"logo": Netflix,
					"link": "https://www.netflix.com/watch/81054417"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/Wv-dDq2xj6g?autoplay=1",
					"poster": SRF_Item1,
					"title": "Series Trailer",
					"description": "Saddle up for new adventures at Palomino Bluffs."
				},
				{
					"video": "https://www.youtube.com/embed/_J2WoJx5azM?autoplay=1",
					"poster": SRF_Item2,
					"title": "Spirit Riding Free: Ride Along Adventure Trailer",
					"description": "You're in control of this interactive adventure when Maricela’s horse is stolen by a group of bandits, and the PALs embark on a daring adventure to rescue her back."
				}
			]
		}
	},
	// TROLLS: TROLLSTOPIA
	{
		"id": 13,
		"layout": "fourth",
		"name": "TROLLS: TROLLSTOPIA",
		"slug": "trolls-trollstopia",
		"logo": TROLL_Logo,
		"poster": TROLL_Poster,
		"inner": {
			"hero": TROLL_Hero,
			"copyright": "Dreamworks Trolls © 2020 Dreamworks Animation LLC. All Rights Reserved.",
			"description": "Inspired by the beloved DreamWorks Animation films, TrollsTopia is the next chapter in the hair-raising adventures of the trolls. Now that Poppy knows there are other musical trolls scattered throughout the forest, she bottles up her endless positivity and invites delegates from every troll tribe in the forest to live together in harmony in a grand experiment she calls TrollsTopia!",
			"credits": [
				{
					"label": "Executive Producer",
					"person": "Matt Beans"
				}
			],
			"streaming": [
				{
					"platform": "Peacock",
					"logo": Peacock,
					"link": "https://www.peacocktv.com/watch-online/tv/trolls-trollstopia/6176499515272572112"
				},
				{
					"platform": "Hulu",
					"logo": Hulu,
					"link": "https://www.hulu.com/series/trolls-trollstopia-a5f2363f-ecc2-494e-9cf9-b896f6d7e574"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/VfORuNBO7o8?autoplay=1",
					"poster": TROLL_Item1,
					"title": "Series Trailer",
					"description": "More tricks! More Treats! More Tiny Diamond!"
				},
				{
					"video": "https://www.youtube.com/embed/DYq6ZFwb9wo?autoplay=1",
					"poster": TROLL_Item2,
					"title": "Tiny Diamond's Imaginary Friend",
					"description": "Meet Shiny Diamond, the new invisible best friend of Tiny Diamond! But will Poppy be able to keep the fantasy alive and become best friends forever with Shiny?"
				},
				{
					"video": "https://www.youtube.com/embed/4_536PhxtAU?autoplay=1",
					"poster": TROLL_Item3,
					"title": "ScareTroll Fashion Show",
					"description": "When Mr. Dinkles starts to eat the Techno Trolls’ dance plants in the TrollsTopia Community Garden, will the trolls be able to stop him from becoming a plant pest? "
				}
			],

			"gallery": {
				"poster": TROLL_Gallery,
				"title": "Art Gallery",
				"btnText": "View Now",
				"images": [
					TROLL_Gallery_1,
					TROLL_Gallery_2,
					TROLL_Gallery_3,
					TROLL_Gallery_4,
					TROLL_Gallery_5
				]
			}

		}
	},
	// WHERE'S WALDO?
	{
		"id": 14,
		"layout": 1,
		"name": "WHERE'S WALDO?",
		"slug": "wheres-waldo",
		"logo": WW_Logo,
		"poster": WW_Poster,
		"inner": {
			"hero": WW_Hero,
			"copyright": "Dreamworks Where's Waldo? © Dreamworks Distribution Limited. All Rights Reserved.",
			"description": "Waldo and Wenda are members of the Worldwide Wanderer Society, the international order of curious travelers who circle the globe celebrating cultures and solving problems through observation. Their mentor, Wizard Whitebeard, a seasoned wanderer, sends the inquisitive young adventurers on international travel missions so they can earn their stripes and someday become wizard-level wanderers too. But standing in their way is their rival Odlulu, who can’t help but cause trouble wherever she goes.",
			"credits": [
				{
					"label": "Executive Producer",
					"person": "F.M. De Marco"
				},
				{
					"label": "Co-Executive Producer",
					"person": "John Tellegen"
				}
			],
			"streaming": [
				{
					"platform": "Peacock",
					"logo": Peacock,
					"link": "https://www.peacocktv.com/watch/playback/vod/GMO_00000000270033_01/057aa7cc-0144-36f0-a918-26b1086c7f63"
				},
				{
					"platform": "Hulu",
					"logo": Hulu,
					"link": "https://www.hulu.com/watch/5010c696-fa68-4c5a-be6b-ff5bb80f3f21"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/yZe1o8Gg4nY?autoplay=1",
					"poster": WW_Item1,
					"title": "Series Trailer",
					"description": "Grab your passports and get ready for all new globetrotting adventures with Waldo and Wenda."
				}
			],
		}
	},

	// GABBYS DOLLHOUSE
	{
		"id": 15,
		"layout": 'fourth',
		"name": "GABBY'S DOLLHOUSE",
		"slug": "gabbys-dollhouse",
		"logo": GD_Logo,
		"poster": GD_Poster,
		"inner": {
			"hero": GD_Hero,
			"copyright": "Testing",
			"description": "Welcome to Gabby's Dollhouse, the preschool show with a surprise inside! Created and executive produced by Traci Paige Johnson (Blue's Clues, Daniel Tiger's Neighborhood) and Jennifer Twomey (Blue's Clues, Team Umizoomi), Gabby's Dollhouse is a mixed media series that unboxes a surprise before jumping into a fantastical animated world full of adorable cat characters that live inside Gabby’s dollhouse. Any adventure can unfold when we play in Gabby's Dollhouse!",
			"credits": [
				{
					"label": "Executive Producers",
					"person": "Traci Paige Johnson and Jennifer Twomey"
				}
			],
			"streaming": [
				{
					"platform": "Netflix",
					"logo": Netflix,
					"link": "https://www.netflix.com/watch/80179762"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/_rPl35ZvmJU?autoplay=1",
					"poster": GD_Item1,
					"title": "Trailer",
					"description": "More dancing, wild adventures, and Cakey-bakey fun with purr-fect friends!"
				},
				{
					"video": "https://www.youtube.com/embed/XnmXXck4ZEU?autoplay=1",
					"poster": GD_Item2,
					"title": "Day at the Dollhouse Hotel",
					"description": "When Gabby unboxes a suitcase and a hotel brochure, it’s time to take a trip!"
				},
				{
					"video": "https://www.youtube.com/embed/C00YO_0Ayrg?autoplay=1",
					"poster": GD_Item3,
					"title": "Knights of the Kitty Kat Castle",
					"description": "Gabby, Pandy and CatRat must complete three acts of knighthood to prove they are worthy of becoming knights of the Kitty Cat Castle!"
				},
			],
			"gallery": {
				"poster": GD_Gallery,
				"title": "Art Gallery",
				"btnText": "View Now",
				"images": [
					GD_Gallery_1,
					GD_Gallery_2,
					GD_Gallery_3,
					GD_Gallery_4,
					GD_Gallery_5
				]
			}
		}
	},

	// FAST & FURIOUS: SPY RACERS
	{
		"id": 16,
		"layout": "first",
		"name": "TROLLHUNTERS RISE OF THE TITANS",
		"slug": "trollhunters-rise-of-the-titans",
		"logo": TH_Logo,
		"poster": TH_Poster,
		"inner": {
			"hero": TH_Hero,
			"copyright": "TBD",
			"description": "On the surface, Arcadia appears to be a slice of timeless Americana, but it is no ordinary town.  It lies at the center of magical and mystical lines that make it a nexus for many battles among otherworldly creatures including trolls, aliens and wizards. Following the events of the Tales of Arcadia trilogy, the heroes of Arcadia must band together to protect humanity from the evil Arcane Order, who wield their dark and uncontrollable magic to summon ancient titans that threaten to destroy the world. ",
			"credits": [
				{
					"label": "TBD",
					"person": "TBD"
				},
				{
					"label": "TBD",
					"person": "TBD"
				}
			],
			"streaming": [
				{
					"platform": "Netflix",
					"logo": Netflix,
					"link": "https://www.netflix.com/watch/80225085"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/3TWN0MWlM7Y?autoplay=1",
					"poster": TH_Item1,
					"title": "Series Trailer",
					"description": "In an epic conclusion to the Tales of Arcadia saga, our heroes must unite their worlds in order to save the universe."
				},
				{
					"video": "https://www.youtube.com/embed/5BV4c7qmbNc?autoplay=1",
					"poster": TH_Item2,
					"title": "Rise of the Titans Creative Team",
					"description": "Hear from the Creative Team behind Trollhunters: Rise of the Titans including creator Guillermo del Toro, Chad Hammes, Johane Matte, Francisco Ruiz Velasco and Andrew Schmidt."
				},
				{
					"video": "https://www.youtube.com/embed/6Ggg1P5ppvk?autoplay=1",
					"poster": TH_Item3,
					"title": "Behind the Animation",
					"description": "Director Francisco Ruiz breaks down the animation secrets of the Titans and their epic fighting in Trollhunters: Rise of the Titans."
				},
			],
			// "consideration": {
			// 	"poster": FF_Consideration,
			// 	"video": "https://www.youtube.com/embed/dW7ui2pegSI?autoplay=1",
			// 	"title": "Rafaela's Escape",
			// 	"description": "Season 3 kicked off with this epic sequence directed by Micah Gunnell.",
			// 	"btnText": "View Now"
			// },

			// "consideration": {
			// 	"poster": TH_Consideration,
			// 	"title": "",
			// 	"description": "",
			// 	"btnText": "View Now",
			// 	"images": [
			// 		TH_Consideration_1,
			// 		TH_Consideration_2,
			// 		TH_Consideration_3,
			// 		TH_Consideration_4,
			// 		TH_Consideration_5,
			// 		TH_Consideration_6,
			// 		TH_Consideration_7,
			// 		TH_Consideration_8,
			// 		TH_Consideration_9,
			// 		TH_Consideration_10
			// 	]
			// },


			"gallery": {
				"poster": TH_Gallery,
				"title": "Art Gallery",
				"btnText": "View Now",
				"images":[
					TH_Gallery_1,
					TH_Gallery_2,
					TH_Gallery_3,
					TH_Gallery_4,
					TH_Gallery_5,
					TH_Gallery_6,
					TH_Gallery_7
				]
			}
		}
	},

	// GABBYS DOLLHOUSE
	{
		"id": 17,
		"layout": 'fourth',
		"name": "THE CROODS: FAMILY TREE",
		"slug": "croods-family-tree",
		"logo": CR_Logo,
		"poster": CR_Poster,
		"inner": {
			"hero": CR_Hero,
			"copyright": "TBD",
			"description": "Inspired by the blockbuster DreamWorks Animation film The Croods: A New Age, The Croods: Family Tree continues the ever-evolving story of the Croods and the Bettermans as they learn to live together on the most idyllic farm in prehistory. The journey from sparring rivals to unlikely friends is fraught with hilarious misadventures as the two families slowly overcome their differences to turn a tree house divided into a tree home united.",
			"credits": [
				{
					"label": "TBD",
					"person": "TBD"
				}
			],
			"streaming": [
				{
					"platform": "Netflix",
					"logo": Netflix,
					"link": "TBD"
				}
			],
			"items": [
				{
					"video": "https://www.youtube.com/embed/KU6vq6b51cw?autoplay=1",
					"poster": CR_Item1,
					"title": "Series Trailer",
					"description": "The future of family fun starts here!"
				},
				{
					"video": "https://www.youtube.com/embed/ABdTeVJw1fg?autoplay=1",
					"poster": CR_Item2,
					"title": "The Bounceberries Taste like Bounceberries",
					"description": "Determined to prove their worth on the Betterman farm, the Croods set out to harvest the seriously sticky bounceberries."
				},
				{
					"video": "https://www.youtube.com/embed/Ol56DVjDs3g?autoplay=1",
					"poster": CR_Item3,
					"title": "GRR Mountain",
					"description": "Dawn and Eep are in for some real mountain mayhem."
				},
			],
			"gallery": {
				"poster": CR_Gallery,
				"title": "Art Gallery",
				"btnText": "View Now",
				"images": [
					CR_Gallery_1,
					CR_Gallery_2,
					CR_Gallery_3,
					CR_Gallery_4,
					CR_Gallery_5,
					CR_Gallery_6,
					CR_Gallery_7,
					CR_Gallery_8
				]
			}
		}
	}


]